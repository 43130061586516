import React from 'react';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import classNames from 'classnames';
import Heading from '@afs/components/Heading';
import { useInView } from 'react-intersection-observer';
import useMedia from '../../../hooks/useMedia';
import config from '../../../config';
import Faqs from './Faqs';
import Button from '../../atoms/Button';
import Picture from '../../atoms/Picture';
import MetaTags from '../../atoms/MetaTags';
import TrustPilotReviewCard from '../../molecules/TrustPilotReviewCard';
import SupportSection from '../../molecules/SupportSection';
import TrustpilotMicrostarRating from '../../atoms/TrustpilotMicrostarRating';
import TrustpilotWidget from '../../molecules/TrustpilotWidget';
import GuideCard from '../../molecules/GuideCard';
import UspSectionWithAdditionalPoint from '../../molecules/UspSectionWithAdditionalPoint';
import PbsaProviderTrustBar from '../../molecules/PbsaProviderTrustBar';
import Header from '../../organisms/Header';
import Footer from '../../organisms/Footer';
import LocationLinks from '../../organisms/LocationLinks';
import HomePageSearchBar from '../../organisms/HomePageSearchBar';
import FAQSection from '../../organisms/FAQSection';
import AdvertiseSection from '../../organisms/AdvertiseSection';
import FeaturedCities from '../../organisms/FeaturedCities';
import PageTemplate from '../../templates/PageTemplate';
import { HomePageViewModel } from './HomePageViewModel';
import Arrow from './arrow.svg';
import styles from './styles.module.scss';
interface AutocompleteSuggestion {
  name: string;
  label: string;
  type: any[];
  placeId: string;
  country: string;
}
interface AutocompleteSuccessResponse {
  success: true;
  suggestions: AutocompleteSuggestion[];
  country: string;
}
interface AutocompleteFailureResponse {
  success: false;
  suggestions?: never;
  country?: never;
}
type AutocompleteResponse = AutocompleteSuccessResponse | AutocompleteFailureResponse;
interface AutocompleteApiFunction {
  (countryCode: string, searchTerm: string): Promise<AutocompleteResponse>;
}
interface CoordinatesResult {
  success: boolean;
  latitude?: number;
  longitude?: number;
  radius?: number;
}
interface GeoCoordinatesApiFunction {
  (countryCode: string, googlePlaceId: string, googleService?: typeof google): Promise<CoordinatesResult>;
}
export interface HomePageProps {
  viewModel: HomePageViewModel;
  initialIsMobileDevice: boolean;
  history: {
    push: (url: string) => void;
  };
  autocompleteApi: AutocompleteApiFunction;
  geoCoordinatesApi: GeoCoordinatesApiFunction;
}
const StudentReviews = dynamic(() => import('../../organisms/StudentReviews'), {
  ssr: false
});
const HomePage = ({
  viewModel,
  initialIsMobileDevice,
  autocompleteApi,
  geoCoordinatesApi,
  history
}: HomePageProps) => {
  const isMobileDevice = useMedia(['(max-width: 580px)'], [true], initialIsMobileDevice);
  const [inViewRef, inView] = useInView({
    threshold: 0.01,
    initialInView: false,
    fallbackInView: false
  });
  const {
    absoluteUrl,
    guides,
    locations,
    featuredCities,
    images,
    reviews
  } = viewModel;
  const {
    heroImageDesktop,
    heroImageTablet,
    metaImage
  } = images;
  return <>
      <Script strategy="beforeInteractive" type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `
                {
                  "@context" : "https://schema.org",
                  "@type" : "WebSite",
                  "additionalType": "Accommodation",
                  "name" : "Accommodation for Students",
                  "url" : "https://www.accommodationforstudents.com",
                  "potentialAction": {
                    "@type": "SearchAction",
                    "target": {
                      "@type": "EntryPoint",
                      "urlTemplate": "https://www.accommodationforstudents.com/search-results?location={search_term_string}"
                     },
                    "query-input": "required name=search_term_string"
                  }
                }
            `
    }} data-sentry-element="Script" data-sentry-source-file="index.tsx" />
      <Script src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" data-sentry-element="Script" data-sentry-source-file="index.tsx" />
      <PageTemplate className={styles.page} header={<Header className={styles.header} />} footer={<Footer variant="warm-gray" />} data-sentry-element="PageTemplate" data-sentry-source-file="index.tsx">
        <MetaTags title="Accommodation for Students: Houses, Halls, Flats & Studios" description="Search the UK's No.1 Student Accommodation website for Student Houses, Halls, Flats & Studios. Find student accommodation in over 130 locations." imageUrl={metaImage} absoluteUrl={absoluteUrl} data-sentry-element="MetaTags" data-sentry-source-file="index.tsx">
          <meta name="google-site-verification" content="rkZysOykD1xH6ZB7fXWsQLwpfwRGDOAGjTH6rDRmiOA" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
          <meta name="google-translate-customization" content="94daeeb0a63739a0-6e3e37542cbe66d5-gc67c98b66183c4dc-20" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
        </MetaTags>

        <section className={classNames(styles.section, styles.heroSection)}>
          <div className={styles.heroSectionInner}>
            <div className={styles.heroLeftColumn}>
              <Heading className={styles.heroHeading} level={1} h1Large data-sentry-element="Heading" data-sentry-source-file="index.tsx">
                The home of student accommodation
              </Heading>

              <p className={styles.heroText}>
                <span>Search UK's No.1 Student</span> Accommodation Website
              </p>
              <HomePageSearchBar className={styles.heroSearch} autocompleteApi={autocompleteApi} geoCoordinatesApi={geoCoordinatesApi} locations={locations} history={history} countryCodeForAutocomplete="afsCountries" data-sentry-element="HomePageSearchBar" data-sentry-source-file="index.tsx" />
              <TrustpilotMicrostarRating className={styles.heroTrustpilotLogo} data-sentry-element="TrustpilotMicrostarRating" data-sentry-source-file="index.tsx" />
            </div>
            <div className={styles.heroRightColumn}>
              <div className={styles.heroImageWrapper}>
                <Picture className={styles.heroImage} optimiseLcp src={`${config.imgApiUrl}w=1075/${heroImageDesktop}`} sources={[{
                id: 'desktop',
                srcSet: `${config.imgApiUrl}w=586/${heroImageDesktop} 1x, ${config.imgApiUrl}w=586,dpr=2/${heroImageDesktop} 2x`,
                media: '(min-width: 921px)'
              }, {
                id: 'tablet',
                srcSet: `${config.imgApiUrl}w=576/${heroImageTablet} 1x, ${config.imgApiUrl}w=576,dpr=2/${heroImageTablet} 2x`,
                media: '(min-width: 481px) and (max-width: 920px)'
              }, {
                id: 'mobile',
                srcSet: `${config.imgApiUrl}w=448/${heroImageTablet} 1x, ${config.imgApiUrl}w=448,dpr=2/${heroImageTablet} 2x`,
                media: '(max-width: 480px) '
              }]} alt="Smiling woman in orange jumper" data-sentry-element="Picture" data-sentry-source-file="index.tsx" />
              </div>
            </div>
          </div>
        </section>

        <UspSectionWithAdditionalPoint className={styles.section} data-sentry-element="UspSectionWithAdditionalPoint" data-sentry-source-file="index.tsx" />

        <section className={classNames(styles.section, styles.featuredCitiesSection)} data-testid="featured-cities-section">
          <Heading level={2} className={styles.featuredCitiesHeading} data-sentry-element="Heading" data-sentry-source-file="index.tsx">
            Explore popular student cities
          </Heading>
          <FeaturedCities className={styles.featuredCities} featuredCities={featuredCities} shouldDisplayPropertyCount data-sentry-element="FeaturedCities" data-sentry-source-file="index.tsx" />
        </section>

        <section className={classNames(styles.section, styles.guidesSection)}>
          <div className={styles.guidesHeadingWrapper}>
            <Heading className={styles.guidesHeading} level={2} data-sentry-element="Heading" data-sentry-source-file="index.tsx">
              Check out our useful student guides
            </Heading>
            <Button className={styles.guidesLinkButton} to="/student-guides" data-testid="desktop-link-button" data-sentry-element="Button" data-sentry-source-file="index.tsx">
              See all guides <Arrow className={styles.guidesLinkArrow} data-sentry-element="Arrow" data-sentry-source-file="index.tsx" />
            </Button>
          </div>

          <div className={styles.guides}>
            {guides.map(guide => <GuideCard key={guide.link} className={styles.guide} guide={guide} homePageVariant />)}
          </div>

          <div className={styles.guidesButtonWrapper}>
            <Button className={styles.guidesButtonMobile} to="/student-guides" data-testid="mobile-link-button" data-sentry-element="Button" data-sentry-source-file="index.tsx">
              See all guides <Arrow className={styles.guidesLinkArrow} data-sentry-element="Arrow" data-sentry-source-file="index.tsx" />
            </Button>
          </div>
        </section>

        <AdvertiseSection className={classNames(styles.section, styles.advertiseSection)} data-sentry-element="AdvertiseSection" data-sentry-source-file="index.tsx" />

        <PbsaProviderTrustBar className={styles.section} data-sentry-element="PbsaProviderTrustBar" data-sentry-source-file="index.tsx" />

        <section ref={inViewRef} className={classNames(styles.section, styles.reviewsSection)} data-testid="reviews-section">
          <Heading className={classNames(styles.reviewsHeading, {
          [styles.reviewsHeadingTrustpilotNotLoaded]: !inView
        })} level={2} data-sentry-element="Heading" data-sentry-source-file="index.tsx">
            What students think of us
          </Heading>
          <TrustpilotWidget isMobileDevice={isMobileDevice} inView={inView} data-sentry-element="TrustpilotWidget" data-sentry-source-file="index.tsx" />
          <StudentReviews className={styles.studentReviews} colourVariant="colour" reviews={reviews} cardVariant={TrustPilotReviewCard} data-sentry-element="StudentReviews" data-sentry-source-file="index.tsx" />
          <Button className={styles.reviewsLinkButton} to="https://uk.trustpilot.com/review/www.accommodationforstudents.com" data-sentry-element="Button" data-sentry-source-file="index.tsx">
            See more reviews <Arrow className={styles.reviewsLinkArrow} data-sentry-element="Arrow" data-sentry-source-file="index.tsx" />
          </Button>
        </section>

        <FAQSection className={classNames(styles.section, styles.faqSection)} heading={<Heading level={3} className={styles.faqSectionHeading}>
              Your questions answered
            </Heading>} subheading={<strong className={styles.faqSectionSubheading}>
              Common questions
            </strong>} questions={Faqs} data-sentry-element="FAQSection" data-sentry-source-file="index.tsx" />

        <SupportSection className={classNames(styles.section, styles.supportSection)} data-sentry-element="SupportSection" data-sentry-source-file="index.tsx" />

        <LocationLinks className={classNames(styles.section, styles.locationLinks)} heading="Student accommodation locations" locations={viewModel.locations} shouldDisableLinksWithNoProperties data-testid="location-links" data-sentry-element="LocationLinks" data-sentry-source-file="index.tsx" />
      </PageTemplate>
    </>;
};
export default HomePage;