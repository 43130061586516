import React, { useEffect, useRef, useState } from 'react';
import MainTrustpilotWidget from './MainTrustpilotWidget';
export interface TrustPilotWidgetProps {
  isMobileDevice: boolean;
  inView: boolean;
}
const TrustpilotWidget = ({
  isMobileDevice,
  inView
}: TrustPilotWidgetProps) => {
  const ref = useRef(null);
  const trustpilot = typeof window === 'undefined' ? null : window?.Trustpilot;
  useEffect(() => {
    if (window.Trustpilot && ref.current && inView) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [ref.current, inView, trustpilot]);
  if (!inView) return null;
  return <MainTrustpilotWidget ref={ref} isMobile={isMobileDevice} data-sentry-element="MainTrustpilotWidget" data-sentry-component="TrustpilotWidget" data-sentry-source-file="index.tsx" />;
};
export default TrustpilotWidget;